<template>
    <div>
        <div v-if="elements.length > 0"
             v-loading="isLoading"
             class="d-flex flex-column flex-fill">
            <VuePerfectScrollbar style="height: calc(100dvh - 18rem); background-color: white;">
                <div class="w-100 container-fluid">
                    <div class="row">
                        <div class="w-100 pb-1 col-12 col-md-6" :key="element.userId"
                             v-for="element in elements">
                            <slot name="item-template" v-bind:element="element"></slot>
                        </div>
                    </div>
                </div>
            </VuePerfectScrollbar>
        </div>
        <div v-else v-loading="isLoading" class="d-flex flex-column flex-fill">
            <div style=" padding-right: 0px !important; background-color: white; height: calc(100dvh - 18rem); "
                 class="d-flex align-items-center justify-content-center flex-column">
                <img src="/icon/nodata2.svg" style="opacity: 0.25; width: 50%; max-width: 30rem;" />
                <div class="mt-3" style="color: #9e9e9e; font-size: 1.5rem">
                    <strong>EMPTY LIST</strong>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            isLoading: {
                type: Boolean,
                default() { return false; },
            },
            elements: {
                type: Array,
                default() { return []; },
            },
        },
        methods: {
            
        }
    }
</script>